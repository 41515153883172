<template>
  <div class="single-news-page">
    <div class="single-news__binance container-fluid">
      <div class="container">
        <div class="news-box">
          <div class="title">
            <span>{{ $t('news_page.news.title') }}</span>
          </div>
          <div class="smartForex">
            <div class="color"></div>
            <div class="item">
              <div class="title">
                <span>{{ $t('news_page.news.smart') }}</span>
              </div>
              <div class="date">
                <span>2021-11-23 03:59</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="single-news__item container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-sm-7 body">
            <div class="body__image">
              <img src="./img/singleCoin.png" alt="bitcoin" />
            </div>
            <div class="body__title">
              <span>{{ $t('news_page.news.fellow') }}</span>
            </div>
            <div class="body__description">
              <span>{{ $t('news_page.news.description') }}</span>
              <p class="p_description">
                {{ $t('news_page.news.description_2') }}
              </p>
              <p class="p_description">
                {{ $t('news_page.news.description_3') }}
              </p>
            </div>
            <div class="body__note">
              <span>{{ $t('news_page.news.note') }}</span>
            </div>
            <div class="body__topic amp">
              <div class="topic__title">
                <span class="yellow">{{ $t('news_page.news.what') }}</span>
              </div>
              <div class="topic__description">
                <span>{{ $t('news_page.news.topic') }}</span>
              </div>
            </div>

            <div class="body__topic pla">
              <div class="topic__title">
                <span class="yellow">{{ $t('news_page.news.what_play') }}</span>
              </div>
              <div class="topic__description">
                <span>{{ $t('news_page.news.topic_description') }}</span>
              </div>
              <div class="topic__details">
                <div class="details__title">
                  <span>{{ $t('news_page.news.details') }}</span>
                </div>
                <div class="details__info">
                  <span>{{ $t('news_page.news.amp') }}</span>
                  <span>{{ $t('news_page.news.pla') }}</span>
                  <span>{{ $t('news_page.news.amp_20') }}</span>
                  <span>{{ $t('news_page.news.pla_20') }}</span>
                  <span>{{ $t('news_page.news.pla_polygon') }}</span>
                  <span>{{ $t('news_page.news.fees') }}</span>
                  <span>{{ $t('news_page.news.rules') }}</span>
                </div>
              </div>
            </div>

            <div class="body__warning">
              <div class="warning__title item">
                <span>{{ $t('news_page.news.risk') }}</span>
              </div>
              <div class="warning__description item">
                <span>{{ $t('news_page.news.risk_descripiton') }}</span>
                <p class="item">{{ $t('news_page.news.thanks') }}</p>
              </div>
            </div>

            <div class="body__buttons">
              <button class="previous">
                {{ $t('news_page.news.previous') }}
              </button>
              <button class="next">{{ $t('news_page.news.next') }}</button>
            </div>
          </div>
          <div class="col-md-5 col-sm-5 aside">
            <div class="aside-search">
              <img src="./img/search-img.svg" alt="search" />
              <input type="search" />
            </div>
            <div class="aside__articles">
              <span>{{ $t('news_page.news.related') }}</span>
            </div>
            <div class="aside__news">
              <div class="binance__news item">
                <img src="./img/bNews.png" alt="binanceNews" />
                <p>{{ $t('news_page.news.latest') }}</p>
              </div>
              <div class="fiat__news item">
                <img src="./img/fNews.png" alt="fiatNews" />
                <p>{{ $t('news_page.news.new_fiat') }}</p>
              </div>
              <div class="crypto__news item">
                <img src="./img/cNews.png" alt="cryptoNews" />
                <p>{{ $t('news_page.news.crypto') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('news_page.news.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" src="./styles.scss"></style>
